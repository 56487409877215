import axios from 'axios';
import { API_URL, APIV, REQUEST_RESPONSE_ENCRYPTION } from './../../config/bike_refinance_constant';
import secureStorage from './../../config/encrypt';
import { toast } from 'react-toastify';
import crypto from "crypto-browserify";

const refinanceAxios = axios.create();
refinanceAxios.interceptors.response.use(
    (response) => {
        if (response.data && response.data.status != 200) {
            console.error(`${response.data.status} - ${response.data.message}`, response.data)
            // if(response.data.status == 400)
            //     window.commonErrorToast(response.data.message)
            //     toast.error(`${response.data.status} - ${response.data.message}`);
        }
        return response;
    },
    (error) => {
        // handle error
        if (error.response) {
            console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
            if (error.response.status == 503) {
                // toast.error(`${error.response.status} - ${error.response.statusText}`);
            } else if (error.response.status == 401) {
                // if(error.response.data.errors == "TokenEmptyInCache") 
                //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
                window.location.href = '/logout';
            }
        }

        return error.response;
    });

    

window.commonErrorToast = function (error) {
    if(typeof error == "string") toast.error(error);
    else if(error.message) toast.error("Helllo",error.message);
}
let masterServices = {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
        return (fetchHeader && fetchHeader.data && fetchHeader.data.token)
            ? fetchHeader.data.token : null;
    },
    delete(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return refinanceAxios.delete(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    post(URL, body, headers, apiTimeout) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        let responsePromise;
        let routeArray = URL?.split('/') || [];
        if(routeArray?.length && routeArray[0] == "bike-refinance"){
            headers["is_encrypt_response"] = headers.is_encrypt_response ?? 1;
            if(body){
                body = hybridEncrypt(body, REQUEST_RESPONSE_ENCRYPTION.public_key)
            }
        }
        if(apiTimeout){
            responsePromise = axios.post(`${API_URL.GETWAY_API}${URL}`, body, {headers, "timeout":apiTimeout,"timeoutErrorMessage":"Something went wrong.\n Please reload the page and try again."})  
        }else{
            responsePromise = refinanceAxios.post(`${API_URL.GETWAY_API}${URL}`, body, {headers})
        }
        return new Promise((resolve,reject)=>{
            responsePromise.then((response)=>{
                if(response.data.encrypted_symmetric_key){
                    let {encrypted_data, encrypted_symmetric_key, iv} = response.data; 
                    let decryptedResponse = hybridDecrypt(encrypted_data, encrypted_symmetric_key, iv);
                    response.data = decryptedResponse
                }
                resolve(response)
            }).catch(error => reject(error))
        })
        
    },
    put(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return refinanceAxios.put(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    get(URL, query, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return refinanceAxios.get(`${API_URL.GETWAY_API}${URL}`,
            { params: query, headers })
    }
}

const hybridEncrypt = (data, public_key) => {
    let symmetric_key = crypto.randomBytes(32)//.toString("base64");
    const iv = crypto.randomBytes(16);

    const cipher = crypto.createCipheriv(REQUEST_RESPONSE_ENCRYPTION.aes_algo, symmetric_key, iv); // IV should be random and unique for each encryption
    let encrypted_data = cipher.update(JSON.stringify(data), 'utf8', 'base64');
    encrypted_data += cipher.final('base64');

    const encrypted_symmetric_key = crypto.publicEncrypt(public_key, symmetric_key).toString('base64');

    return {encrypted_data, encrypted_symmetric_key, iv: iv.toString("base64")};
};

const hybridDecrypt = (encrypted_data, encrypted_symmetric_key, iv) => {
    let decipher = crypto.createDecipheriv(REQUEST_RESPONSE_ENCRYPTION.aes_algo, REQUEST_RESPONSE_ENCRYPTION.response_aes_key, Buffer.alloc(16, 0));
    let symmetric_key = decipher.update(encrypted_symmetric_key, 'base64', 'utf8');
    symmetric_key += decipher.final('utf8');

    decipher = crypto.createDecipheriv(REQUEST_RESPONSE_ENCRYPTION.aes_algo, Buffer.from(symmetric_key, 'base64'), Buffer.from(iv, 'base64'));
    let decrypted_data = decipher.update(encrypted_data, 'base64', 'utf8');
    decrypted_data += decipher.final('utf8');

    return JSON.parse(decrypted_data);
};


export default masterServices;