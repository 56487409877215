import React, { useState, useRef, useEffect } from 'react';
import { GeneralService } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigData } from '../../store/action/allAction';
import { useParams } from 'react-router-dom';
import { API_KEY, AUTH_KEY, FID_DOC_PARENT_ID, GOOGLE_MAP_KEY } from '../../config/constant';
import masterServices from '../../services/bike-refinance/MasterService';
import { toast } from 'react-toastify';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
const CameraCapture = () => {
  let { token, financier_id,lead_id } = useParams();
  const [hasCamera, setHasCamera] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [docUpload,setDocupload]=useState([]);
  const [docId,setDocId]=useState(0);
  const [isVideo,setIsVideo]=useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [documentList, setDocumentList] = useState(null);
  const [modalType,setModalType]=useState("");
  const [authData,setAuthData]=useState({});
  const [VIDEO_DOC_ID,setVideoDocId]=useState(0)
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const dispatch = useDispatch();
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: '',
    error: null,
  });
  const [loading, setLoading] = useState(false);

 useEffect(async()=>{
  await verifyToken();
},[lead_id])

const verifyToken=async()=>{
  setLoading(true);
  masterServices.post('loan/shf/authenticate_url', {validation_code:token, lead_id: lead_id,user_type:"fid_bm"  },{authKey:AUTH_KEY})
    .then(function (response) {
      if (response?.data?.status == 200) {
        setLoading(false);
        setAuthData(response?.data?.data);
      }else{
        toast.error(response?.data?.message);
        setModalType('invalid');
        setLoading(false);
        setTimeout(function () {
          window.location.href = "/not_found";
        }, 2000)
      }
    }).catch(function (error) {
      setModalType('invalid');
      if (error.code === 'ECONNABORTED') {
        toast.error(error.message)
      } else {
        console.log(error.message);
      }
      setLoading(false);
    })
}

  useEffect(() => {
        getConfig();
}, []);

const getConfig = () => {
    let configObj = {
        "config_data": [
            "document_list"
        ],
        "dealer_id": 0,
        "source": "WEB",
        // "is_redis_force_update":true
    }

    masterServices.post('loan/config/get',configObj ,{apiKey:API_KEY})
    .then(function (response) {
      if (response?.data?.status == 200) {
        setLoading(false);
        let docList=response?.data?.data?.document_list?.filter((dl)=>+dl?.parent_id===FID_DOC_PARENT_ID && dl?.is_video==='0')
        setDocumentList(docList);
        let videoId=response?.data?.data?.document_list?.filter((dl)=>+dl?.parent_id===FID_DOC_PARENT_ID && dl?.is_video==='1')?.[0]?.id || 0;
        setVideoDocId(videoId)
      }else{
        toast.error(response?.data?.message);
        setModalType('invalid');
        setLoading(false);
        setTimeout(function () {
          window.location.href = "/not_found";
        }, 2000)
      }
    }).catch(function (error) {
      setModalType('invalid');
      if (error.code === 'ECONNABORTED') {
        toast.error(error.message)
      } else {
        console.log(error.message);
      }
      setLoading(false);
    })
}

  useEffect(() => {
    // Check if Geolocation is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          setLocation({
            latitude: lat,
            longitude: lon,
            address: '',
            error: null,
          });

          // Now get the address using Google Maps Geocoding API
          await getAddressFromCoordinates(lat, lon);
        },
        (error) => {
          toast.warning("Please enable location from browser")
          setLocation({
            latitude: null,
            longitude: null,
            address: '',
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        address: '',
        error: 'Geolocation is not supported by this browser.',
      });
    }
  }, []);


   // Function to get the address from latitude and longitude
   const getAddressFromCoordinates = async (lat, lon) => {
    setLoading(true);

    const apiKey = GOOGLE_MAP_KEY; // Replace with your Google Maps API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`;
    
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        setLocation((prevState) => ({
          ...prevState,
          address: data.results[0].formatted_address,
        }));
      } else {
        setLocation((prevState) => ({
          ...prevState,
          address: '',
        }));
      }
    } catch (error) {
      setLocation((prevState) => ({
        ...prevState,
        address: 'Error fetching address.',
      }));
    } finally {
      setLoading(false);
    }
  };

  // Start the camera when component mounts
  useEffect(() => {
    if (videoRef.current && !hasCamera) {
      startCamera();
    }
  }, [hasCamera]);

  // Start the camera
  const startCamera = async (doc_id=0) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setHasCamera(true);
      if(doc_id===VIDEO_DOC_ID) setIsVideo(true);
      else setIsVideo(false);

      // Ensure videoRef is valid before assigning srcObject
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
 
      setDocId(doc_id);
      // Setup the media recorder
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        chunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const videoBlob = new Blob(chunksRef.current, { type: 'video/webm' });
        
        setVideoUrl(URL.createObjectURL(videoBlob));
        var formData = new FormData();
        formData.append("upload_type", 'finance_video');
        formData.append("lead_id", authData?.lead_id);
            formData.append('images', videoBlob, 'video.webm');
         let s3Data=await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
         let imageUrl=s3Data?.data?.data?.[0]?.file_url;
         let image_name=s3Data?.data?.data?.[0]?.file_name;
         let file_size=s3Data?.data?.data?.[0]?.file_size;

         let docData=docUpload;
         let selectedDoc=docData.find((du)=>du?.doc_id===VIDEO_DOC_ID);
           if(selectedDoc){
              selectedDoc['image_data']=videoUrl;
              selectedDoc['image_url']=imageUrl;
              selectedDoc['image_name']=image_name;
              selectedDoc['file_size']=file_size;
             selectedDoc['doc_id']=docId
           }else{
             docData.push({doc_id:VIDEO_DOC_ID,image_data:videoUrl,image_url:imageUrl,file_size:file_size,image_name:image_name})
           }
           setDocupload([...docData]);
           
console.log(s3Data,"ssssssssssssssss")
        chunksRef.current = []; // reset chunks
      };
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (err) {
      console.error('Error accessing camera: ', err);
    }
  };

  // Capture the image from the video feed
  const capturePhoto = async () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    if (video && canvas && context) {
      // Draw the video frame to the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
        // Text to be added
        const text = location.address;

        // Set font and text style
        context.font = '10px Arial';
        context.fillStyle = 'red';

        // Calculate text width
        const textWidth = context.measureText(text).width;

        // Set the position to the bottom-right corner
        const x = canvas.width - textWidth - 10;  // 10px padding from the right edge
        const y = canvas.height - 10;  // 10px padding from the bottom edge

        // Add the text
        context.fillText(text, x, y);

      // Get the image data (Base64 encoded)
      const imageData = canvas.toDataURL('image/png');
      
       // Convert canvas to Blob
    canvas.toBlob(async function(blob) {
        var formData = new FormData();
        formData.append("upload_type", 'finance_docs');
        formData.append("lead_id", authData?.lead_id);
            formData.append('images', blob, 'capture.png');
         let s3Data=await GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' });
         stopCamera();
        let imageUrl=s3Data?.data?.data?.[0]?.file_url;
        let image_name=s3Data?.data?.data?.[0]?.file_name;
        let file_size=s3Data?.data?.data?.[0]?.file_size;
         let docData=docUpload;
      let selectedDoc=docData.find((du)=>du?.doc_id===docId);
        if(selectedDoc){
          selectedDoc['image_data']=imageData;
          selectedDoc['image_url']=imageUrl;
          selectedDoc['image_name']=image_name;
          selectedDoc['file_size']=file_size;
        }else{
          docData.push({doc_id:docId,image_data:imageData,image_url:imageUrl,image_name: image_name,file_size:file_size})
        }
        setDocupload([...docData]);
//console.log(s3Data?.data?.data?.[0]?.file_url,"xxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    }, 'image/png');

     
      //setPhoto(imageData);
    }
  };

  // Stop the camera
  const stopCamera = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
      }
    }
    setHasCamera(false);
    setDocId(0);
  };

  const removeImage = (doc_id=0) =>{
    let docData=docUpload;
     docData=docData.filter((du)=>du?.doc_id!==doc_id);
      setDocupload([...docData]);
      if(doc_id===VIDEO_DOC_ID){
        setVideoUrl(null)
      }
  }

  // Start recording video
  const startRecording = () => {
    if (mediaRecorderRef.current && !isRecording) {
      mediaRecorderRef.current.start();
      setIsRecording(true);
    }
  };

  // Stop recording video
  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setHasCamera(false);
        setDocId(0)
    }
  };

  const saveDoc  = () => {
    let docListIds=documentList?.map((dl)=> +dl.id );
    // Check if every ID exists in the array of objects
    const allIdsExist = docListIds.every(id => 
      docUpload.some(obj => +obj.doc_id === id)
    );
    if(!allIdsExist) {
      toast.error("Please Upload all docs");
      return;
    }

    let saveDocData=[];
    docUpload?.map((du)=>{
      saveDocData.push({"created_by":authData?.bm_id,"finance_lead_id":authData?.lead_id,"doc_path":du?.image_name,"doc_aws_path":du?.image_url,"file_size":du?.file_size,doc_id:du?.doc_id,parent_id:FID_DOC_PARENT_ID})
    })
    let finalData={validation_code:token,is_fid:true, latitude: location?.latitude.toString(), longitude: location?.longitude.toString(), "source":"WEB","finance_lead_id":authData?.lead_id,"lead_docs":saveDocData}

    masterServices.post('loan/lead/save_doc',finalData ,{apiKey:API_KEY})
    .then(function (response) {
      if (response?.data?.status == 200) {
        
        toast.success("Doc Uploaded");
        setTimeout(()=>{
          setLoading(false);
          window.location.href="/";
        },2000);
        
      }else{
        toast.error(response?.data?.message);
        setLoading(false);
        setTimeout(function () {
          window.location.href = "/not_found";
        }, 2000)
      }
    }).catch(function (error) {
      setModalType('invalid');
      if (error.code === 'ECONNABORTED') {
        toast.error(error.message)
      } else {
        console.log(error.message);
      }
      setLoading(false);
    })

  }
  
  let isDisabled=false;
  let docListIds=documentList?.map((dl)=> +dl.id );
    // Check if every ID exists in the array of objects
    if(docListIds && docListIds?.length){
        isDisabled = docListIds.every(id => 
      docUpload.some(obj => +obj.doc_id === id)
      );
    }
  return (
//     <div>

// <div>
//       <h3>Current Location</h3>
//       {location.error ? (
//         <p>Error: {location.error}</p>
//       ) : (
//         <>
//           <p>
//             Latitude: {location.latitude}, Longitude: {location.longitude}
//           </p>
//           {loading ? (
//             <p>Loading address...</p>
//           ) : (
//             <p>Address: {location.address || 'Not available'}</p>
//           )}
//         </>
//       )}
//     </div>

//       <h1>Camera Capture</h1>

//       {!hasCamera && (
//         <button onClick={startCamera}>Start Camera</button>
//       )}

//       {hasCamera && (
//         <>
//           <video
//             ref={videoRef}
//             width="100%"
//             height="auto"
//             autoPlay
//             muted
//           ></video>
//           <br />
//           <button onClick={capturePhoto}>Capture Photo</button>
//           <button onClick={stopCamera}>Stop Camera</button>

//           <canvas ref={canvasRef} width={640} height={480} style={{ display: 'none' }}></canvas>
//         </>
//       )}

//       {photo && (
//         <div>
//           <h2>Captured Photo:</h2>
//           <img src={photo} alt="Captured" />
//         </div>
//       )}
//     </div>

<div className='fig-cap-outer-bg'>

{hasCamera && (
        <div className=''>
          <video
            ref={videoRef}
            width="100%"
            height="auto"
            autoPlay
            muted
          ></video>
          <br />
          {!isVideo &&
          <>
          <button className='btn-line mxs-r' onClick={capturePhoto}>Capture Photo</button>
          <button className='btn-line m-sm-l' onClick={stopCamera}>Stop Camera</button>
            </>
        }
      { isVideo &&
      <>
          <button className='btn-line mxs-r' onClick={startRecording} disabled={isRecording}>Start Recording</button>
          <button className='btn-line m-sm-l' onClick={stopRecording} disabled={!isRecording}>Stop Recording</button>
      </> }


          <canvas ref={canvasRef} width={640} height={480} style={{ display: 'none' }}></canvas>
        </div>
      )}
  <div >
    <h3 className='fid-heading p-lg-t'>Geo Tag Car Pics</h3>

<div className='dif-mmv'>
    
      <div className='fid-loan-id'>
      Loan Id : {authData?.lead_id}
     
      <span>{authData?.customer_name || ""}</span>
    </div>

    <div className='dealer-name-gcd-code'>
      <span>{authData?.dealer_name}</span>
     <span>GCD {authData?.dealer_id}</span>
    </div>

    <div className='fid-detail-list'>
      <ul>
        <li>
          <label>Make</label>
          <span>{authData?.car_brand}</span>
        </li>
        <li>
          <label>Year Of MFG</label>
          <span>{authData?.make_year}</span>
        </li>
        <li>
          <label>Model</label>
          <span>{authData?.car_model}</span>
        </li>
        <li>
          <label>Plate Number</label>
          <span>{authData?.plate_number}</span>
        </li>
        <li>
          <label>Version</label>
          <span>{authData?.car_varient}</span>
        </li>
       
      </ul>
      

</div>
</div>
<div className='geo-taging-pic-card'>
  <Accordion allowZeroExpanded>
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
         
            <h3 className='fid-heading'>Geo tag Car Pics</h3>
          
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <ul className='geo-taging-img-upload'>
        {documentList && documentList?.length>0 && documentList?.map((dl,k)=>{
          return(
            <li className='' key={k}>
              <span className='fid-doc-head'>{dl?.name} *</span>
             
                <div class="fid-image-container">
                  <img alt='' src={docUpload?.find((du)=>+du.doc_id===+dl?.id)?.image_data} className="fid-image-src"/>
                  { docUpload?.find((du)=>+du.doc_id===+dl?.id)?.image_data &&
                  <span onClick={()=>removeImage(dl?.id)} className="fid-cross-icon"><i className='ic-clearclose'></i></span>
                  }
                  { !docUpload?.find((du)=>+du.doc_id===+dl?.id)?.image_data &&
                  <button disabled={!location?.latitude || !location?.longitude} onClick={()=>startCamera(dl?.id)} className="fid-upload-button"  ><i className='ic-add'></i>Upload Photo</button>
                  }
                </div>
                
            </li>
          )
        })}
        <li className=''>
          <span className='fid-doc-head'>Video of the Car with Engine and Chassis(Optional)</span>
          <div class="fid-image-container">
                {videoUrl &&
                <video width="400" height="400" controls>
                  <source src={videoUrl} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
                }

                  { videoUrl &&
                  <span onClick={()=>removeImage(VIDEO_DOC_ID)} className="fid-cross-icon"><i className='ic-clearclose'></i></span>
                  }
                  { !videoUrl &&
                  <button disabled={!location?.latitude || !location?.longitude} onClick={()=>startCamera(VIDEO_DOC_ID)} className="fid-upload-button"  ><i className='ic-add'></i>Upload Video</button>
                  }
          </div>
        </li>
        </ul>
      </AccordionItemPanel>
    </AccordionItem>
  </Accordion>

  
{/* <div className='col-md-12' >
        <p className='fid-doc-head fid-video-head'>Video of the Car with Engine and Chassis</p>
        <div className='row'>
          <div className='col-md-12'>
              <div class="">
                {videoUrl &&
                <video width="400" height="400" controls>
                  <source src={videoUrl} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
                }
                { videoUrl &&
                <span onClick={()=>removeImage(VIDEO_DOC_ID)} className="fid-cross-icon">✖</span>
                }
                { !videoUrl &&
                <button disabled={!location?.latitude || !location?.longitude}  onClick={()=>startCamera(VIDEO_DOC_ID)} className="fid-upload-button"  >Upload Video</button>
                }
              </div>
            </div>
          </div>
                <br></br><br></br>
          <div className='col-md-12'>
           
          </div>

      </div> */}

      <button disabled={!isDisabled} onClick={()=>saveDoc()} className='btn btn-primary fid-submit btn-full-width'>Submit Documents</button>
      
</div>
  </div>
</div>
  );
};

export default CameraCapture;
